<template>
  <section class="page-row sitemap">
    <div class="container ">
      <div class="sitemap-container col col--12-12">
        <p class="mono-medium">&lt;sitemap/&gt;</p>
        <router-link class="link" :to="{name: 'Home'}">Home</router-link>
        <router-link class="link" :to="{name: 'Diensten'}">Diensten</router-link>
        <router-link class="link" :to="{name: 'Contact'}">Contact</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Sitemap',
  head: {
    title: {
      inner: 'Sitemap',
    },
    meta: [
      { name: 'description', content: 'Kroot. Sitemap', id: 'desc' },
      { property: 'og:description', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:title', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:url', content: 'https://kroot.dev/sitemap' },
      { name: 'twitter:card', content: 'Kroot. Digitaal Vakmanschap' },
    ],
    link: [
      { rel: 'canonical', href: 'https://kroot.dev' },
    ],
  },
};
</script>

<style lang="scss" scoped>
.sitemap {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: left;

  .container {
    margin-top: 80px * 4;

    .link {
      &:first-of-type {
        margin-top: 10px;
      }
      line-height: 30px;
      display: flex;
    }
  }
}
</style>
